<template>
  <div class="artist-menu-component" v-loading.fullscreen.lock="loading">
    <el-dropdown trigger="click">
      <button
        class="
          btn btn-sm btn-icon btn-bg-light btn-active-color-primary
          el-dropdown-link
        "
      >
        <i class="bi bi-three-dots fs-3"></i>
      </button>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item
            v-if="buttons.includes('analytics')"
            @click="routeToAnalyticsPage(artist.name)"
            >Analytics</el-dropdown-item
          >
          <el-dropdown-item
            v-if="buttons.includes('artistDetails')"
            @click="routeToArtistDetailPage(artist.uuid)"
            >Artist Detail</el-dropdown-item
          >
          <el-dropdown-item
            v-if="buttons.includes('discover')"
            @click="routeToDiscoverPage"
            >Listen</el-dropdown-item
          >
          <el-dropdown-item
            v-if="buttons.includes('watchlist')"
            @click="showWatchListModal(artist.uuid)"
            >Watchlist</el-dropdown-item
          >
          <el-dropdown-item
            v-if="buttons.includes('crawl')"
            @click="routeToCrawlPage(artist.uuid)"
            >Crawl</el-dropdown-item
          >
          <el-dropdown-item
            v-if="buttons.includes('crm')"
            @click="addToCrm(artist.uuid)"
            >+ CRM</el-dropdown-item
          >
          <el-dropdown-item
            v-if="buttons.includes('saveArtist')"
            @click="saveArtist(artist.uuid)"
            >Save Artist</el-dropdown-item
          >
          <el-dropdown-item v-if="showFollowButton">
            <Follow :uuid="artist.uuid" :is-followed="artist.isFollowed" />
          </el-dropdown-item>
          <el-dropdown-item
            v-if="buttons.includes('aiSandbox')"
            @click="routeToSandboxPage(artist.uuid)"
            >AI Sandbox</el-dropdown-item
          >
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>
import { DrawerComponent } from "@/assets/ts/components";
import Follow from "../../common/components/Follow.vue";
import { useRouter } from "vue-router";
import { ref } from "vue";
import { useStore } from "vuex";
import { getArtistUUIDBySpotifyPayload } from "@/api/artists.api";
import { ElNotification } from "element-plus";
import { addArtistToCRM } from "@/api/hubspot";
import { saveArtistApi } from "@/api/user.api";
export default {
  name: "ArtistMenuComponent",
  props: {
    artist: {
      type: Object,
      required: true,
    },
    fromDrawer: {
      type: Boolean,
      default: false,
    },
    spotifyObject: {
      type: Object,
      default: null,
    },
    buttons: {
      type: Array,
      required: true,
      // validator: (propValue) => {
      //   return ["artistDetails", "discover", "watchlist", "crawl"].includes(
      //     propValue
      //   );
      // },
    },
    showFollowButton: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Follow,
  },

  setup(props) {
    const addWatchlistModalMenuComponentRef = ref(null);
    const loading = ref(false);
    const router = useRouter();
    const store = useStore();

    const showWatchListModal = async (uuid) => {
      let uuidLocal = uuid ? uuid : await getArtistUUIDBySpotifyData();
      if (uuidLocal) {
        store.commit(
          "ArtistModule/SET_SHOW_WATCHLIST_MODAL_ARTIST_UUID",
          uuidLocal
        );
      }
    };

    const hideDrawer = () => {
      DrawerComponent.getInstance("artistViewDrawer").hide();
    };

    const routeToAnalyticsPage = (name) => {
      if (name) {
        window.open(`/artists?search=${name}`, "_blank");
      }
    };

    const routeToArtistDetailPage = async (uuid) => {
      let uuidLocal = uuid ? uuid : await getArtistUUIDBySpotifyData();
      if (uuidLocal) {
        if (props.fromDrawer) {
          hideDrawer();
          router.push({
            path: `/artists/${uuidLocal}/overview`,
          });
        } else {
          window.open(`/artists/${uuidLocal}/overview`, "_blank");
        }
      }
    };

    const getArtistUUIDBySpotifyData = async () => {
      if (props.spotifyObject && Object.keys(props.spotifyObject).length > 0) {
        loading.value = true;
        try {
          const { data } = await getArtistUUIDBySpotifyPayload(
            props.spotifyObject
          );
          return data.uuid;
        } catch (e) {
          ElNotification({
            title: "Error",
            message: "No match found for this artist",
            type: "error",
          });
        } finally {
          loading.value = false;
        }
      }
      return null;
    };

    const routeToDiscoverPage = () => {
      // if (props.fromDrawer) {
      //   hideDrawer();
      // }
      store.dispatch("DiscoverModule/changeSelectedMenu", "Search");
      window.open(`/discover?search=${props.artist.name}`, "_blank");
    };

    const routeToCrawlPage = async (uuid) => {
      loading.value = true;
      let uuidLocal = uuid ? uuid : await getArtistUUIDBySpotifyData();
      loading.value = false;
      window.open(
        `/crawler?search=${props.artist.name}${
          uuidLocal ? "&uuid=" + uuidLocal : ""
        }`,
        "_blank"
      );
    };

    const addToCrm = async (uuid) => {
      loading.value = true;
      let uuidLocal = uuid ? uuid : await getArtistUUIDBySpotifyData();
      if (uuidLocal) {
        try {
          await addArtistToCRM(uuidLocal);
        } catch (e) {
          ElNotification({
            title: "Error",
            message: "Error adding to CRM, please try later!",
            type: "error",
          });
        } finally {
          loading.value = false;
        }
      } else {
        ElNotification({
          title: "Error",
          message: "Error adding to CRM, please try later!",
          type: "error",
        });
      }
      loading.value = false;
    };
    const saveArtist = async (uuid) => {
      loading.value = true;
      let uuidLocal = uuid ? uuid : await getArtistUUIDBySpotifyData();
      if (uuidLocal) {
        try {
          await saveArtistApi(uuidLocal);
          await store.dispatch("UserModule/fetchDiscogsSearchHistory", true);
          localStorage.setItem(
            "fetch-discogs-search-history",
            Math.random() + "discogs-search-history"
          );
        } catch (e) {
          //
        } finally {
          loading.value = false;
        }
      } else {
        ElNotification({
          title: "Error",
          message: "Error in saving Artist, please try later!",
          type: "error",
        });
      }
      loading.value = false;
    };
    const routeToSandboxPage = (uuid) => {
      if (uuid) {
        window.open(
          `${process.env.VUE_APP_SANDBOX_URI}/?uuid=${uuid}`,
          "_blank"
        );
      }
    };
    return {
      addWatchlistModalMenuComponentRef,
      loading,
      showWatchListModal,
      routeToArtistDetailPage,
      routeToDiscoverPage,
      routeToCrawlPage,
      routeToAnalyticsPage,
      addToCrm,
      saveArtist,
      routeToSandboxPage,
    };
  },
};
</script>

<style scoped></style>
